  <template>
    <div class="center">
      <b-modal id="modal-no-backdrop" hide-footer no-close-on-backdrop content-class="shadow" title="Add User"
        ok-title="Accept">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>Nama Lengkap</label>
                  <validation-provider #default="{ errors }" name="Nama Lengkap" rules="required">
                    <b-form-input v-model="name" :state="errors.length > 0 ? false:null" placeholder="Nama Lengkap" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Email</label>
                  <validation-provider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input v-model="email" :state="errors.length > 0 ? false:null" type="email"
                      placeholder="Email" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>No Handphone</label>
                  <validation-provider #default="{ errors }" name="NoHP" rules="required">
                    <b-form-input v-model="no_hp" :state="errors.length > 0 ? false:null" type="text"
                      placeholder="No HP / WA " />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Username</label>
                  <validation-provider #default="{ errors }" name="Username" rules="required">
                    <b-form-input v-model="username" :state="errors.length > 0 ? false:null" type="text"
                      placeholder="Username" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <label>Level</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Level" rules="required">
                    <v-select rules="required" :state="errors.length > 0 ? false:null" v-model="level"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="level_name" :options="levelList" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Password</label>
                  <validation-provider #default="{ errors }" name="Password" rules="required">
                    <b-form-input v-model="password" :state="errors.length > 0 ? false:null" type="password"
                      placeholder="Password" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <hr>
              <b-col cols="6">
                <b-button class="mb-3 mt-1" variant="outline-primary" block type="submit" @click.prevent="actSave">
                  <i class="fa fa-save mr-1"></i>Simpan
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button class="mb-3 mt-1" variant="outline-warning" block @click="$bvModal.hide('modal-no-backdrop')">
                  <i class="fa fa-close mr-1"></i> Keluar</b-button>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
    import axios from '@/config/Axios';
    import Base from '@/config/Mixins_base';
    import {
      ValidationProvider,
      ValidationObserver
    } from 'vee-validate'
    import {
      required,
      email
    } from '@validations'
    import {
      BModal,
      BButton,
      VBModal, 
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
      props: {
        active: {}
      },
      mixins: [Base],
      data: () => ({
        name: '',
        username: '',
        email: '',
        password: '',
        required,
        email,
        level: {
          level_name: 'Admin'
        },
        no_hp : "",
        levelList: []

      }),
      emits: ["get_user"],
      components: {
        BModal,
        BButton,
        VBModal, 
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BCardText,
        vSelect,
      },
      mounted() {
        this.getLevel();
      },
      methods: {
        async getLevel() {
          const self = this;
          await axios({
              method: 'PUT',
              url: '/api/acl/user/level',
              data: {

              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.levelList = response.data.result;

            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        },
        async AddUser() {
          const self = this;
          await axios({
              method: 'POST',
              url: '/api/acl/user/add',
              data: { 
                password: self.password, 
                username: self.username,
                nm_lengkap: self.name, 
                level_key: self.level.level_key, 
                email  : self.email,
                no_hp : self.no_hp
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.notification('warning', "info", response.message);
              self.$emit("get_user", "");

            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        },
        actSave() {
          this.$refs.simpleRules.validate().then(success => {
            if (success) {
              this.AddUser();
              this.$root.$emit('bv::hide::modal', 'modal-no-backdrop');
            }
          })
        },
      },

    }
  </script>