var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center"},[_c('b-modal',{attrs:{"id":"modal-no-backdrop","hide-footer":"","no-close-on-backdrop":"","content-class":"shadow","title":"Add User","ok-title":"Accept"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Lengkap")]),_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Lengkap"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("No Handphone")]),_c('validation-provider',{attrs:{"name":"NoHP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"No HP / WA "},model:{value:(_vm.no_hp),callback:function ($$v) {_vm.no_hp=$$v},expression:"no_hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Username")]),_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Level")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":"required","state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"level_name","options":_vm.levelList},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Password")]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr'),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-1",attrs:{"variant":"outline-primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actSave($event)}}},[_c('i',{staticClass:"fa fa-save mr-1"}),_vm._v("Simpan ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-1",attrs:{"variant":"outline-warning","block":""},on:{"click":function($event){return _vm.$bvModal.hide('modal-no-backdrop')}}},[_c('i',{staticClass:"fa fa-close mr-1"}),_vm._v(" Keluar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }